import getBasicDomNodeProfile from './getBasicDomNodeProfile';

export function getNParents(element, nParents = 5, parents = []) {
  let parent = element.parentNode;

  if (nParents === 0 || !parent) {
    return parents;
  }

  let remainingParents = nParents - 1
  if (
    (
      element.tagName
      && (
        ['button', 'select', 'input', 'a'].includes(element.tagName.toLowerCase())
      )
    )
    || (
      element.getAttribute('role')
      && (
        ['button', 'checkbox', 'radio', 'link'].includes(element.getAttribute('role').toLowerCase())
      )
    )
  ) {
    remainingParents = 5
  }

  return getNParents(
    parent,
    remainingParents,
    parents.concat({
      ...getBasicDomNodeProfile(parent),
      nth_parent: parents.length + 1
    })
  )
}
